import anime from 'animejs/lib/anime.es.js';

document.addEventListener(
    'DOMContentLoaded',
    function () {
        var mobileMenuAnimation = anime({
            targets: '.mobile-nav .menu__list a',
            translateY: ['180%', '0%'],
            duration: 340,
            easing: 'easeOutQuad',
            autoplay: false,
            delay: anime.stagger(60, { start: 150 }),
            loop: false,
        });

        var mobileBackgroundAnimation = anime({
            targets: '.mobile-nav__wrapper',
            opacity: [0, 1],
            duration: 340,
            easing: 'easeOutQuad',
            autoplay: false,
            loop: false,
        });

        var logoAnimation = anime({
            targets: '.site-header__logo',
            scale: [1, 1.22],
            duration: 300,
            easing: 'easeOutQuad',
            autoplay: false,
            loop: false,
        });

        let burgerLink = document.querySelector('.site-header__burger');
        let burgerIcon = burgerLink.querySelector('.ham');
        let mobileNavigation = document.querySelector('.mobile-nav__wrapper');
        let mobileLinks = mobileNavigation.querySelectorAll('.menu-item a');

        burgerLink.addEventListener('click', function () {

            if (mobileBackgroundAnimation.began) {
                mobileBackgroundAnimation.reverse();

                if (mobileBackgroundAnimation.progress === 100 && mobileBackgroundAnimation.direction === 'reverse') {
                    mobileBackgroundAnimation.completed = false;
                }
            }

            if (mobileBackgroundAnimation.paused) {
                mobileBackgroundAnimation.play();
            }

            mobileMenuAnimation.restart();
            mobileNavigation.classList.toggle('is-visible');
            document.body.classList.toggle('no-scroll');
        });

        mobileLinks.forEach(function (link) {
            link.addEventListener('click', function () {

                if (mobileBackgroundAnimation.began) {
                    mobileBackgroundAnimation.reverse();

                    if (mobileBackgroundAnimation.progress === 100 && mobileBackgroundAnimation.direction === 'reverse') {
                        mobileBackgroundAnimation.completed = false;
                    }
                }

                if (mobileBackgroundAnimation.paused) {
                    mobileBackgroundAnimation.play();
                }

                mobileNavigation.classList.remove('is-visible');
                document.body.classList.remove('no-scroll');
                burgerIcon.classList.remove('active');

            });
        });
    },
    false
);